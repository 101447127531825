import { useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import { useMediaQuery } from 'react-responsive'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { Address } from 'manage-tritag/services/api/endpoints/associations'
import LocationPicker from 'manage-tritag/components/location-picker'
import {
  Participant,
  useAddParticipantMutation,
} from 'manage-tritag/services/api/endpoints/participants'
import Spinner from 'manage-tritag/components/loading/spinner'
import { DatePicker } from '@mui/x-date-pickers'
import {
  FormContent,
  LogoContainer,
  SecondFormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
  UploadContainer,
} from './styles'
import uploadImage from '../../../images/upload.png'

const ParticipantCreateForm = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [createParticipant, { isLoading: createLoading }] =
    useAddParticipantMutation()
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      gender: '',
      contact: {
        email: '',
        phone: '',
      },
      emergencyContact: {
        name: '',
        phone: '',
      },
    },
  })
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [logo, setLogo] = useState<any>(undefined)

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleFileChange = (e: any) => {
    setLogo(URL.createObjectURL(e.target.files[0]))
    setSelectedFile(e.target.files[0])
  }

  const onSubmit: SubmitHandler<Participant> = async (createData: any) => {
    if (createLoading) return
    const fileData = new FormData()
    const reqData = {
      ...createData,
      address,
      headshot: '',
    }
    fileData.append('formData', JSON.stringify(reqData))
    fileData.append('file', selectedFile!)
    await createParticipant(fileData).unwrap()
    reset()
    toast.success('Participant added succesfully.')
    navigate('/participants')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={4}>
          {logo ? (
            <LogoContainer onClick={handleFileClick}>
              <img
                src={logo}
                height="200"
                alt="logo"
                placeholder="blurred"
                style={{
                  height: '180px',
                  width: '180px',
                  objectFit: 'cover',
                }}
              />
            </LogoContainer>
          ) : (
            <UploadContainer onClick={handleFileClick}>
              <Typography
                variant="subtitle2"
                component="p"
                style={{ marginBottom: '0.8rem', fontWeight: 'bold' }}
              >
                Upload your logo
              </Typography>
              <Typography variant="subtitle2" style={{ fontSize: '0.8rem' }}>
                PNG and JPG file
              </Typography>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{ fontSize: '0.8rem' }}
              >
                format only
              </Typography>
              <img
                height="50"
                src={uploadImage}
                alt=""
                style={{ marginTop: '1rem' }}
              />
            </UploadContainer>
          )}

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#008174',
              marginBottom: '1rem',
              marginTop: isMobile ? '2rem' : '0',
            }}
          >
            Participant details
          </Typography>

          <FormContent>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="First name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 2 }}
                  id="filled-basic"
                  label="Last name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Date of Birth"
                  InputProps={{ disableUnderline: true, error: false }}
                  renderInput={(renderParams: any) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 3 }}
                      id="filled-basic"
                      variant="filled"
                      size="small"
                      {...renderParams}
                      required
                    />
                  )}
                />
              )}
            />

            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Gender *
              </InputLabel>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 4 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                    required
                  >
                    <MenuItem value="male">
                      <em>Male</em>
                    </MenuItem>
                    <MenuItem value="female">
                      <em>Female</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormContent>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <SecondFormContent>
            <Controller
              name="contact.email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 5 }}
                  id="filled-basic"
                  label="Email"
                  variant="filled"
                  size="small"
                  required
                  {...field}
                />
              )}
            />

            <Controller
              name="contact.phone"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 6 }}
                  id="filled-basic"
                  label="Mobile"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <LocationPicker tabIndex={7} setAddress={setAddress} />
          </SecondFormContent>

          <FormContent>
            <Controller
              name="emergencyContact.name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 8 }}
                  id="filled-basic"
                  label="Emergency contact"
                  variant="filled"
                  size="small"
                  required
                  {...field}
                />
              )}
            />

            <Controller
              name="emergencyContact.phone"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 9 }}
                  id="filled-basic"
                  label="Emergency contact number"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            {!isMobile && <Box sx={{ width: '100%' }} />}
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/participants')
            }}
            style={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: '83px' }}
          >
            {createLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default ParticipantCreateForm
