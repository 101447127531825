import { useState } from 'react'
import { Tab, Tabs, Box } from '@mui/material'
import Layout from 'manage-tritag/components/layout'
import CreateForm from 'manage-tritag/components/pages/participants/create-form'
import TabPanel from 'manage-tritag/components/tab-panel'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const CreateParticipantPage = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Layout label1="Participants" label2="Create" url="/participants">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '1rem' }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Member details" sx={{ fontWeight: 'bold' }} />
            <Tab label="Registration history" sx={{ fontWeight: 'bold' }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CreateForm />
        </TabPanel>
        <TabPanel value={value} index={1}>
          TBD
        </TabPanel>
      </Box>
    </Layout>
  )
}

export default withAuthenticationRequired(CreateParticipantPage)
